/*--
/*  4.2 - Clients & About CSS
/*----------------------------------------*/

// Client Section 
.client-section{
    position: relative;
    z-index: 2;
}


.client-wrapper{
    padding: 75px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);

    @media #{$tablet-device}{
        padding: 55px 0;
    }
    @media #{$large-mobile}{
        padding: 35px 0;
    }

    & .image-box{
        opacity: 0.5;
        transition: $transition-base;

        &:hover{
            opacity: 1;
        }
    }
}


// About Section 
.about-section{
    position: relative;
}

// About Images 
.about-images{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50%;
    height: 116%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right bottom;

    @media #{$tablet-device, $large-mobile}{
        position: relative;
        width: 720px;
        height: 727px;
        margin: 0 auto;
        margin-bottom: 40px;
        padding: 0 15px;
    }
    @media #{$large-mobile}{
        width: 540px;
        height: 545px;
    }
    @media #{$small-mobile}{
        width: 320px;
        height: 323px;
    }
}

// About Content 
.about-content{
    & .title{
        font-size: 48px;
        font-weight: 700;
        color: $primary;
        text-transform: uppercase;

        @media #{$small-mobile}{
            font-size: 28px;
        }
    }
    & .about-text{
        font-size: 20px;
        line-height: 1.6;
        margin-top: 36px;

        @media #{$small-mobile}{
            font-size: 16px;
        }
    }
    & p{
        color: $white;
        margin-bottom: 25px;
    }
    & .signechar{
        padding-top: 20px;
    }
    & .name{
        font-size: 14px;
        font-weight: 600;
        font-family: $font-family-base;
        color: $white;
        text-transform: uppercase;
        margin-top: 15px;
    }
}


// About banner 
.about-banner-section{
    position: relative;
    
    & .social{
        position: absolute;
        bottom: 70px;
        left: 70px;
        z-index: 6;

        @media #{$laptop-device, $desktop-device, $tablet-device, $large-mobile}{
            left: 40px;
            bottom: 40px;
        }
        @media #{$large-mobile}{
            display: flex;
        }

        @media #{$small-mobile}{
            left: 20px;
            bottom: 20px;
        }

        & li{
            & + li{
                margin-top: 15px;

                @media #{$large-mobile}{
                    margin-top: 0px;
                    margin-left: 20px;
                }
            }
            & a{
                font-size: 18px;
                color: $white;
                transition: $transition-base;

                &:hover{
                    color: $primary;
                }
            }
        }
    }
}

// About banner 
.about-banner{
    height: 820px;
    z-index: 3;
    background-color: #070707;
    position: relative;

    @media #{$extraBig-device}{
        height: 965px;
    }

    @media #{$large-mobile}{
        height: 720px;
    }
    @media #{$small-mobile}{
        height: 680px;
    }
}

// About banner Images
.about-banner-image{
    position: absolute;
    bottom:  0;
    right: 0;
    width: 55%;
    text-align: left;
    z-index: -1;

    @media #{$large-mobile}{
        display: none;
    }
}

// About banner Content
.about-banner-content{
    & .banner-title{
        font-size: 60px;
        font-weight: 700;
        color: $white;

        & span{
            color: $primary;
        }

        @media #{$laptop-device}{
            font-size: 50px;
        }
        @media #{$desktop-device}{
            font-size: 42px;
        }
        @media #{$tablet-device, $large-mobile}{
            font-size: 38px;
        }
        @media #{$small-mobile}{
            font-size: 24px;

            & br{
                display: none;
            }
        }
    }
    & .banner-social{
        padding-top: 55px;

        @media #{$laptop-device}{
            padding-top: 45px;
        }
        @media #{$desktop-device}{
            padding-top: 35px;
        }

        @media #{$tablet-device, $large-mobile}{
            padding-top: 25px;
        }

        & p{
            margin-bottom: 2px;
        }
        & ul{
            display: flex;
            
            @media #{$large-mobile}{
                flex-wrap: wrap;
            }
    
            & li{
                position: relative;

                & + li{
                    &::before{
                        content: '-';
                        font-size: 16px;
                        color: $white;
                        margin: 0 10px;
                        font-weight: 700;
                        line-height: 1;
                    }
                }
    
                & a{
                    font-size: 24px;
                    font-weight: 700;
                    font-family: $headings-font-family;
    
                    @media #{$tablet-device}{
                        font-size: 20px;
                    }
                    
                    @media #{$small-mobile}{
                        font-size: 16px;
                    }

                    &.twitter{
                        color: #00ccff;
                    }
                    &.behance{
                        color: #0066ff;
                    }
                    &.dribbble{
                        color: #ff0066;
                    }
                    &.github{
                        color: #ffffff;
                    }
                }
            }
        }
    }
    & .banner-service{
        padding-top: 60px;

        @media #{$laptop-device}{
            padding-top: 50px;
        }
        @media #{$desktop-device}{
            padding-top: 40px;
        }

        @media #{$tablet-device, $large-mobile}{
            padding-top: 30px;
        }

        & .service-text{
            margin-top: 20px;

            & a{
                font-size: 24px;
                font-weight: 700;
                font-family: $headings-font-family;
                color: $white;

                @media #{$small-mobile}{
                    & br{
                        display: none;
                    }
                }
            }
        }
    }
}

