/*--
/*  03.1 - Header Main CSS
/*----------------------------------------*/

// Header 
.header{
    padding: 53px 0;
    transition: $transition-base;

    @media #{$large-mobile}{
        padding: 33px 0;
    }

    &.sticky{
        padding: 25px 0;
        background-color: #111;

        @media #{$large-mobile}{
            padding: 20px 0;
        }
    }
}

// Header Wrapper 
.header-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

// Logo Box 
.logo-box{}

// Header Menu Toggler 
.header-menu-toggler{

    & .menu-toggler{
        position: relative;
        font-size: 30px;
        line-height: 1em;     
        color: $white;   
    }
}

// Off Canvas Menu 
.offcanvas-menu{
    position: fixed;
    right: 0px;
    top: 0;
    width: 300px;
    max-width: 100%;
    height: 100%;
    background-color: $white;
    z-index: 1130;
    transform: translateX(101%);
    transition: all 0.7s ease;

    &.open{
        opacity: 1;
        visibility: visible;
        transition: all 0.7s ease 500ms;
        transform: translateX(0%);        
    }

    & .offcanvas-wrapper{
        position: relative;
    }

    & .close-btn{
        position: absolute;
        right: 16px;
        top: 23px;
        line-height: 30px;
        width: 30px;
        text-align: center;
        font-size: 24px;
        color: #000000;
        cursor: pointer;
        z-index: 10;
        transition: all 0.5s ease;        
    }
    
    & .logo {
        position: relative;
        padding: 20px 20px;
        text-align: left;

        & img {
            max-width: 200px;
        }
    }

    & .primary-menu{
        margin-top: 25px;

        & ul{
            border-top: 1px solid rgba($black, 0.10);
            
            & li{
                position: relative;
                display: block;
                border-bottom: 1px solid rgba($black, 0.10);
                
                & a{
                    position: relative;
                    display: block;
                    line-height: 24px;
                    padding: 14px 20px;
                    font-size: 15px;
                    color: #404040;
                    font-weight: 600;
                    text-transform: uppercase;
                    transition: all 0.5s ease;                    
                }

                & .mobile-menu-expand{
                    display: block;
                    position: absolute;
                    width: 52px;
                    height: 52px;
                    top: 0;
                    right: 0;

                   &::before {
                        position: absolute;
                        content: '';
                        width: 8px;
                        height: 8px;
                        border-left: 1px solid #404040;
                        border-bottom: 1px solid #404040;
                        display: block;
                        position: absolute;
                        top: 18px;
                        right: 20px;
                        transform: rotate(-45deg);
                    }
                    &::after {
                        content: '';
                        position: absolute;
                        left: 0px;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 1px;
                        height: 24px;
                        border-left: 1px solid rgba(0,0,0,0.10);                        
                    }
                }

                & .sub-menu{
                    & li{
                        &:last-child{
                            border-bottom: 0;
                        }

                        & a{

                        }
                    }
                }

                &:hover{
                    & > a{
                        color: $primary;
                    }
                }
            }
        }
    }
}

.overlay{
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1120;
    background: rgba($black, 0.9);
    transform: translateX(101%);  
    opacity: 0;
    visibility: hidden;
    transition: all 0.7s ease 500ms;
    
    &.open{
        opacity: 1;
        visibility: visible;
        transition: all 0.7s ease;
        transform: translateX(0%);        
    }
}





