/*--
/*  4.10 - Projects Details CSS
/*----------------------------------------*/

.project-details-section{
    padding-top: 100px;

    @media #{$tablet-device}{
        padding-top: 70px;
    }
    @media #{$large-mobile}{
        padding-top: 50px;
    }

    & .details-label{
        font-family: $custom-font-family;
        font-weight: 400;
        text-transform: uppercase;
        font-size: 16px;
        white-space: nowrap;
    }
    & p{
        font-family: $custom-font-family;
        font-weight: 400;
    }
}

// Details Images 
.details-images{
    & img{
        width: 100%;
    }
}

// Details cheallenge 
.details-challenge{
    & .title{
        font-size: 60px;
        font-weight: 700;
        color: $white;
        margin-top: 30px;

        @media #{$laptop-device}{
            font-size: 50px;
        }
        @media #{$desktop-device}{
            font-size: 42px;
        }
        @media #{$tablet-device, $large-mobile}{
            font-size: 38px;
        }
        @media #{$small-mobile}{
            font-size: 30px;

            & br{
                display: none;
            }
        }        
    }
}

// Details Info 
.details-info{}

.single-info{
    margin-top: 30px;

    & .details-label{
        font-size: 14px;
    }
    & p{
        font-size: 20px;
        color: $white;
        margin-top: 25px;

        @media #{$small-mobile}{
            font-size: 16px;
        }
    }
}

// Details Middle Content
.details-middle-content{
    border-top: 1px solid rgba($white, 0.15);

    & .title{
        font-size: 48px;
        font-weight: 700;
        color: $white;
        margin-top: -15px;

        @media #{$tablet-device}{
            font-size: 40px;
        }
        @media #{$large-mobile}{
            font-size: 30px;
        }
    }
    & p{
        font-size: 18px;
        margin-top: 35px;
    }
}

// Column Images
.column-images{
    padding-top: 58px;

    @media #{$tablet-device}{
        padding-top: 38px;
    }
    @media #{$large-mobile}{
        padding-top: 18px;
    }
}

// Author Info
.author-info{
    margin-top: 73px;

    @media #{$tablet-device}{
        margin-top: 53px;
    }
    @media #{$large-mobile}{
        margin-top: 43px;
    }

    & .name{
        font-size: 400px;
        font-size: 24px;
        text-transform: uppercase;
        color: $white;
        letter-spacing: 1px;
    }
    & p{
        color: $body-color;
        margin-top: 10px;
    }
}

// Details Social
.details-social{
    display: flex;
    align-items: center;
    padding-top: 95px;
    padding-bottom: 155px;

    @media #{$laptop-device}{
        padding-bottom: 115px;
    }
    @media #{$desktop-device}{
        padding-bottom: 90px;
    }
    @media #{$tablet-device}{
        padding-top: 75px;
        padding-bottom: 75px;
    }
    @media #{$large-mobile}{
        padding-top: 55px;
        padding-bottom: 55px;
    }

    & .social{
        display: flex;
        padding-left: 20px;

        @media #{$small-mobile}{
            padding-left: 0;
        }

        & li{
            margin-left: 30px;

            & a{
                font-size: 24px;
                color: $white;
                line-height: 1;
                transition: $transition-base;

                @media #{$small-mobile}{
                    font-size: 20px;
                }

                &:hover{
                    color: $primary;
                }
            }
        }
    }
}

// Reservation Form
.reservation-form-wrapper{
    border-top: 1px solid rgba($white, 0.15);

    & .title{
        font-size: 48px;
        font-weight: 700;
        color: $white;
        margin-top: -15px;

        @media #{$tablet-device}{
            font-size: 40px;
        }
        @media #{$large-mobile}{
            font-size: 30px;
        }
    }
}

.reservation-form{
    padding: 20px 50px 50px;
    border: 1px solid rgba($white, 0.15);
    margin-top: 100px;

    @media #{$tablet-device}{
        margin-top: 80px;
    }
    @media #{$large-mobile}{
        margin-top: 60px;
    }
    @media #{$small-mobile}{
        padding: 0px 30px 30px;
    }
}

// Next 
.next{
    margin-top: -10px;
    margin-bottom: -10px;

    & a{
        font-size: 60px;
        font-weight: 700;
        color: $white;
        font-family: $custom-font-family;
        font-weight: 400;
        display: inline-block;
        line-height: 1;
        transition: $transition-base;

        @media #{$laptop-device}{
            font-size: 50px;
        }
        @media #{$desktop-device}{
            font-size: 42px;
        }
        @media #{$tablet-device, $large-mobile}{
            font-size: 38px;
        }
        @media #{$small-mobile}{
            font-size: 30px;
        }    
        
        &:hover{
            color: $primary;
        }
    }
}